<template>
  <div>
    <VLayout>
      <VMain>
        <router-view></router-view>
        <v-snackbar v-model="globalStore.error" timeout="2500">
          {{ globalStore.errorMessage }}

          <template v-slot:actions>
            <v-btn color="pink" variant="text" @click="globalStore.error = false">
              Закрити
            </v-btn>
          </template>
        </v-snackbar>
      </VMain>
    </VLayout>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { isLoggined } from "./helpers/session.helper";
import { useRoute, useRouter } from "vue-router";
import { Routes } from "./types/enums/routes.enum";
import { useGlobalStore } from "./stores/global.store";

const route = useRoute();
const router = useRouter();

const globalStore = useGlobalStore();

onMounted(async () => {
  const queryParams = new URL(location.href).searchParams;
  if (queryParams.get("confirmation") == "1") {
    alert(
      "Ваш аккаунт успішно підтверджено! Тепер ви можете увійти в систему."
    );
  }
  if (await isLoggined()) {
    switch (route.path) {
      case Routes.Login:
      case Routes.SignUp:
        router.push(Routes.Admin);
        break;

      default:
        return;
    }
  } else {
    router.push(Routes.Main);
  }
});
</script>

<style lang="scss">
* {
  font-family: "Montserrat", sans-serif;
}

body {
  min-height: 100vh;
  background-repeat: no-repeat;
  background: linear-gradient(
    180deg,
    #fddbdb 0%,
    #d6bede 47.4%,
    #cebee2 80.21%,
    #d5cce7 100%
  );
}

@import "qalendar/dist/style.css";
</style>
