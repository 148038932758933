import { RouteRecordRaw } from "vue-router";
import Login from "@/main/pages/Login.vue";
import LoginPicker from "@/main/pages/LoginPicker.vue";
import SignUp from "@/main/pages/SignUp.vue";
import { Routes } from "./types/enums/routes.enum";
import Dashboard from "./pages/Dashboard.vue";
import frontLayoutVue from "./layouts/front-layout.vue";
import SettingsVue from "./pages/Settings.vue";
import CustomersVue from "./pages/Customers.vue";
import Schedules from "./pages/Schedules.vue";

const routes: RouteRecordRaw[] = [
  {
    path: Routes.Main,
    component: LoginPicker,
  },
  {
    path: Routes.Login,
    component: Login,
  },
  {
    path: Routes.SignUp,
    component: SignUp,
  },
  {
    path: Routes.Admin,
    component: frontLayoutVue,
    children: [
      {
        path: "",
        component: Dashboard,
      },
      {
        path: Routes.AdminCustomers,
        component: CustomersVue,
      },
      {
        path: Routes.AdminSchedule,
        component: Schedules,
      },
      {
        path: Routes.AdminSettings,
        component: SettingsVue,
      },
      {
        path: Routes.Checkout,
        component: () => import("./pages/Checkout.vue"),
      }
    ],
  },
];

export default routes;
