import { defineStore } from "pinia";
import { Service } from "../types/service.type";
import { Employee } from "../types/employee.type";

interface GlobalStore {
  services: Service[];
  employees: Employee[];
  error: boolean;
  errorMessage: string;
}

export const useGlobalStore = defineStore("globalStore", {
  state: (): GlobalStore => ({
    services: [],
    employees: [],
    error: false,
    errorMessage: "",
  }),
  persist: {
    paths: ["services", "employees"],
  },
  actions: {
    triggerError(errorMessage: string) {
      this.error = true;
      this.errorMessage = errorMessage;

      setTimeout(() => {
        this.error = false;
        this.errorMessage = "";
      }, 5000);
    }
  }
});
