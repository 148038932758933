<template>
  <div class="schedule-list">
    <div class="schedule-item schedule-list__item" v-for="weekday in weekDays">
      <div class="schedule-item__switcher">
        <v-switch
          :disabled="customSchedule"
          :label="weekObject[weekday].label"
          :class="weekObject[weekday].enabled ? 'enabled' : 'disabled'"
          :hide-details="true"
          inset
          v-model="weekObject[weekday].enabled"
        ></v-switch>
      </div>
      <div class="schedule-item__picker">
        <VueDatePicker
          v-if="weekObject[weekday].enabled"
          locale="uk"
          time-picker
          range
          :disabled="customSchedule"
          disable-month-year-select
          hours-increment="1"
          minutes-increment="15"
          minutes-grid-increment="15"
          class="schedule-item__timepicker"
          :start-time="startTime"
          :utc="true"
          teleport="body"
          :clearable="false"
          v-model="weekObject[weekday].value"
        >
          <template #input-icon />
          <template #clock-icon />

          <template
            #dp-input="{
              value,
              onInput,
              onEnter,
              onTab,
              onClear,
              onBlur,
              onKeypress,
              onPaste,
              isMenuOpen,
            }"
          >
            <div
              :class="[
                'schedule-item__input',
                customSchedule ? 'disabled' : '',
              ]"
            >
              <input
                :value="value"
                readonly
                inputmode="none"
                @input="onInput"
                @keydown.enter="onEnter"
                @keydown.tab="onTab"
                @blur="onBlur"
                @keypress="onKeypress"
                @keydown="onKeypress"
                @paste="onPaste"
                placeholder="Виберіть час"
              />
            </div>
          </template>
          <template
            #action-row="{ internalModelValue, selectDate, closePicker }"
          >
            <div class="dp__action_buttons">
              <button
                type="button"
                class="dp__action_button dp__action_cancel"
                @click="closePicker"
              >
                Відмінити
              </button>
              <button
                type="button"
                class="dp__action_button dp__action_select"
                @click="selectDate"
              >
                Вибрати
              </button>
            </div>
          </template>
        </VueDatePicker>

        <div v-else>Зачинено</div>
      </div>
    </div>

    <div class="schedule-item schedule-list__item" v-if="!isEmployee">
      <div class="schedule-item__switcher">
        <v-switch
          label="Вільний графік"
          color="green"
          :hide-details="true"
          inset
          v-model="customSchedule"
        ></v-switch>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { watch } from "vue";
import {
  WeekObject,
  WorkingHoursSchedule,
} from "../types/working-hours-schedule.type";
import {
  convertWeekObjectToWorkingHoursSchedule,
  revertFromWorkingHoursScheduleToWeekObject,
} from "../helpers/utils.helper";

const emit = defineEmits<{
  (e: "update:weekobject", value: WorkingHoursSchedule[]): void;
  (e: "update:custom", value: boolean): void;
}>();

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const startTime = ref([
  { hours: 0, minutes: 0 },
  { hours: 23, minutes: 0 },
]);

const props = withDefaults(
  defineProps<{ isEmployee: boolean; schedules?: WorkingHoursSchedule[] }>(),
  {
    isEmployee: false,
  }
);

const weekObject = reactive<WeekObject>(
  revertFromWorkingHoursScheduleToWeekObject(props.schedules)
);

const customSchedule = ref(false);

watch(weekObject, (newValue, oldValue) => {
  const workingHours = convertWeekObjectToWorkingHoursSchedule(newValue);
  emit("update:weekobject", workingHours);
});

watch(customSchedule, (newValue, oldValue) => {
  emit("update:custom", newValue);
});
</script>

<style scoped lang="scss">
.schedule-list {
  &__item {
  }
}

.schedule-item {
  display: flex;
  justify-content: space-between;
  &__switcher {
    .enabled {
      :deep(.v-switch__track) {
        background-color: #e7eaee !important;
      }
      .v-switch__thumb {
        background-color: #403838;
      }
    }
  }

  &__picker {
    display: flex;
    align-items: center;
  }

  &__timepicker {
  }

  &__input {
    position: relative;
    width: 100%;

    &.disabled {
      &::after {
        color: #a89d9d;
      }
      input {
        color: #a89d9d;

        &::placeholder {
          color: #a89d9d;
        }
      }
    }

    input {
      padding: 15px;
      cursor: pointer;
      width: 100%;
      outline: none;
      color: #807575;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }

    &::after {
      content: "\F0142";
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 25px;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: calc(50% - 10px);
      right: 10px;
      color: #807575;
    }
  }
}
</style>
